<template>
  <div class="loading-page">
    <div class="loading-icon" id="demoCanvas" ref="demoCanvas"></div>
  </div>
</template>

<script>

import SVGA from 'svgaplayerweb'
export default {
  name: 'Loading',
  data () {
    return {

    }
  },
  mounted () {
    var mycanvas = document.getElementById("demoCanvas");
    // 修改容器大小
    mycanvas.style.width = 100 + "px"; 
    mycanvas.style.height = 100 + "px";
    
    let player = new SVGA.Player("#demoCanvas");
    let parser = new SVGA.Parser("#demoCanvas");
    // this.imageUrl 定义一个参数接收url
    parser.load('./loading.svga', function (videoItem) { 
      player.setVideoItem(videoItem);
      player.startAnimation();
    });
  }
}
</script>

<style lang="less" scoped>
.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99;
  .loading-icon {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -50px 0 0 -50px;
  }
}
</style>
